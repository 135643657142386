import {
  type ClientLoaderFunctionArgs,
  Navigate,
  useLoaderData,
} from '@remix-run/react';
import { useRef } from 'react';
import { useTitle } from 'react-use';
import { $path } from 'remix-routes';

import { useOneTimePurchaseAnalytics } from '../analytics/oneTimePurchase';
import { GamePackUtils } from '../components/Game/GamePack/utils';
import { OTPUtils } from '../components/OneTimePurchase';
import { PurchaseMethod } from '../components/OneTimePurchase/type';
import { ProductUtils } from '../components/Product/utils';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const headcount = OTPUtils.ParseHeadcount(url.searchParams.get('headcount'));

  return {
    headcount,
  };
}

export function Component() {
  const { pack, product } = useCheckoutLoaderData();
  const { headcount } = useLoaderData<typeof clientLoader>();
  const analytics = useOneTimePurchaseAnalytics();
  useTitle(makeTitle(`Purchase ${pack.name}`));

  const trackedRef = useRef(false);

  // If headcount is not given, redirect to the headcount page
  if (!headcount) {
    return (
      <Navigate
        to={$path('/checkout/headcount', window.location.search)}
        replace
      />
    );
  }

  // If the headcount is too large, redirect to the schedule call page
  const packPrice = GamePackUtils.FindOneTimePrice(pack, headcount);
  const productPrice = ProductUtils.FindPrice(product, headcount);
  if (!productPrice) {
    return (
      <Navigate
        to={$path('/checkout/book-a-demo', window.location.search)}
        replace
      />
    );
  }

  if (!trackedRef.current) {
    trackedRef.current = true;
    analytics.trackEventPurchaseStarted({
      gamePackId: pack.id,
      gamePackName: pack.name,
      headcount,
      priceId: packPrice,
      type: OTPUtils.GamePackType(pack),
    });
  }

  // the headcount is good to go, redirect to the purchase flow
  const params = new URLSearchParams(window.location.search);
  params.set('purchase-method', PurchaseMethod.OneTimePurchase);
  if (packPrice) params.set('price-id', packPrice.id);
  params.set('headcount', headcount.toString());
  return (
    <Navigate to={$path('/checkout/choose-purchase-option', params)} replace />
  );
}
